import React from "react";
import { css, jsx } from "@emotion/react";
import { GlobalNavigationBar } from "../components/GlobalNavigationBar";
import SiteHead from "../head";
import { PageHero } from "../components/PageHero";
import { RecoilRoot } from "recoil";

const PageStyle = css`
  padding-bottom: 10rem;
  background: #171717;

  h2 {
    font-size: 3rem;
    margin-top: 20rem;
    margin-bottom: 2rem;

    @media (max-width: 768px) {
      font-size: 1.5rem;
      margin-top: 7rem;
      margin-bottom: 1.25rem;
    }
  }

  .intro {
    max-width: 32rem;
    font-size: 1.375rem;
    font-weight: 100;
    text-align: center;
    line-height: 1.636;
    margin-bottom: 7.5rem;
    margin-left: auto;
    margin-right: auto;

    @media (max-width: 768px) {
      font-size: 1.25rem;
      margin-top: 3rem;
      margin-bottom: 3rem;
    }
  }

  .box-content {
    list-style: none;
    padding: 3.75rem;
    background: rgba(255, 255, 255, 0.05);

    li {
      margin-bottom: 3rem;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .item-title {
      font-size: 1.5rem;
      margin-bottom: 0.75rem;
    }

    .item-content {
      font-size: 1rem;
      line-height: 1.56;
    }

    @media (max-width: 768px) {
      padding: 1.875rem 0.625rem;

      li {
        margin-bottom: 1.75rem;
      }

      .item-title {
        font-size: 1.25rem;
        margin-bottom: 0.5rem;
      }

      .item-content {
        font-size: 1rem;
      }
    }
  }

  .references {
    margin-top: 2.5rem;
    font-size: 0.875rem;
    font-weight: 100;
    line-height: 2;

    ul {
      margin-top: 1.25rem;
      padding-left: 1rem;
      line-height: 1.5;

      a {
        color: inherit;
        text-decoration: none;
      }
    }
  }
`;

export const Head = () => <SiteHead meta={{ title: "행동 강령 | EXCELCON" }} />;

const CocPage = () => {
  return (
    <RecoilRoot>
      <div css={PageStyle}>
        <GlobalNavigationBar />
        <PageHero>
          <h1>행동 강령</h1>
          <p>이것만은 꼭 기억해 주세요!</p>
        </PageHero>
        <div className="container">
          <h2 className="text-center">꼭 기억해 주세요!</h2>
          <p className="intro">
            <strong>EXCELCON</strong>은 다양한 주제의 지식을 함께 공유하고
            다양한 사람들과 함께 즐거운 네트워킹을 즐기는 곳입니다. 서로의 좋은
            컨퍼런스 경험을 위해 꼭 지켜주세요.
          </p>
          <ul className="box-content">
            <li>
              <div className="item-title">
                <span className="text-secondary">01</span> 서로를 존중해주세요.
              </div>
              <div className="item-content">
                <strong>EXCELCON</strong>은 서로에 대한 존중을 바탕으로 참여하는
                곳입니다. 성별, 장애, 외모, 연령, 학력, 경력, 언어 등 차별을
                포함하는 발언은 하지 말아 주세요.
              </div>
            </li>
            <li>
              <div className="item-title">
                <span className="text-secondary">02</span> 적극적으로 참여해
                주세요.
              </div>
              <div className="item-content">
                누구나 즐겁게 <strong>EXCELCON</strong>에 참여할 수 있도록
                적극적으로 참여해 주세요. 서로의 거리를 줄이면 엑셀콘은 보다 큰
                경험이 됩니다.
              </div>
            </li>
            <li>
              <div className="item-title">
                <span className="text-secondary">03</span> 책임감 있게 행동해
                주세요.
              </div>
              <div className="item-content">
                <strong>EXCELCON</strong>은 참여자의 행동으로 완성됩니다. 말과
                행동으로 인해서 타인이 불쾌감을 느끼지 않도록 책임감 있는 모습을
                보여주세요.
              </div>
            </li>
            <li>
              <div className="item-title">
                <span className="text-secondary">04</span> 발표자를 존중해
                주세요.
              </div>
              <div className="item-content">
                발표가 진행되고 있다면 청중과 발표자에게 방해가 되는 행동을 하지
                말아 주세요. 질문은 네트워킹 시간에 이야기해주세요.
              </div>
            </li>
            <li>
              <div className="item-title">
                <span className="text-secondary">05</span> 함께하는 공간을
                존중해 주세요.
              </div>
              <div className="item-content">
                엑셀 플레이스는 서로의 즐거운 지식 공유와 네트워킹을 위한
                공간입니다. 맛있게 먹고 즐긴 자리는 다음 사람을 위해 정돈해
                주세요.
              </div>
            </li>
          </ul>
          <p className="references">
            <strong>참고 문서</strong>
            <br />
            EXCELCON의 행동 강령은 앞서 공유해주신 생각들을 참고해
            작성되었습니다.
            <ul>
              <li>
                <a href="https://infcon.day/notice/" target="_blank">
                  https://infcon.day/notice/
                </a>
              </li>
              <li>
                <a href="https://festa.io/events/2371" target="_blank">
                  https://festa.io/events/2371
                </a>
              </li>
              <li>
                <a
                  href="https://2022.jsconf.kr/ko/code-of-conduct"
                  target="_blank"
                >
                  https://2022.jsconf.kr/ko/code-of-conduct
                </a>
              </li>
            </ul>
          </p>
        </div>
      </div>
    </RecoilRoot>
  );
};

export default CocPage;
